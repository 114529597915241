<template>
  <div>
    <div class="form--group form--group-switch">
      <div class="left--col">
        <span>{{ $t('addListing.listingType') }}</span>
        <span class="required">*</span>
      </div>
      <div class="right--col">
        <div v-if="formListing === 'edit'" class="listing--form-card">
          {{ listingType === 'R' ? $t('general.rent') : $t('addListing.sell') }}
        </div>
        <button
          v-if="formListing !== 'edit'"
          type="button"
          class="btn px-4"
          :class="{ selected: listingType === 'R' }"
          @click="setListingType('R')"
        >
          {{ $t('general.rent') }}
        </button>
        <button
          v-if="formListing !== 'edit'"
          type="button"
          class="btn px-4"
          :class="{ selected: listingType === 'S' }"
          @click="setListingType('S')"
        >
          <span style="position: relative;">
            {{ $t('addListing.sell') }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'rent-buy-switch',
  computed: {
    ...mapState({
      listingType: state => state.v2.listingForm.listingType,
      formListing: state => state.v2.listingForm.form,
    }),
    ...mapGetters({
      selectedPropertyType: 'v2/listingForm/sect1/location/selectedPropertyType',
    }),
  },
  methods: {
    setListingType(newType) {
      if (this.listingType !== newType) {
        this.updateListingType(newType);
      }
    },
    updateListingType(value) {
      if (this.selectedPropertyType) {
        this.$swal({
          title: this.$i18n.t('warning.addListing.modal.typeChangeTitle'),
          text: this.$i18n.t('warning.addListing.modal.typeChangeMessage'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$i18n.t('general.btn.continue'),
          cancelButtonText: this.$i18n.t('general.btn.cancel'),
          allowOutsideClick: false,
        }).then(result => {
          if (result.value === true) {
            this.changeListingType(value);
          }
        });
      } else {
        this.changeListingType(value);
      }
    },
    async changeListingType(listingType) {
      await this.$store.dispatch('v2/listingForm/sect1/basicInfo/applyDefaultValue');
      this.$store.commit('v2/listingForm/SET_LISTING_TYPE', listingType);
      this.$store.commit('v2/listingForm/sect1/location/SET_SELECTED_PROPERTY_NAME', null);
      this.$store.commit('v2/listingForm/sect1/location/RESTORE_DEFAULT_ADD_STATE');
      try {
        this.$store.commit('v2/listingForm/SET_LOADING', true);
        await this.$store.dispatch('v2/listingForm/sect1/hideAllHiddenForms');
        await this.$store.dispatch('v2/listingForm/fetchBaseData');
        this.$emit('changeType');
      } finally {
        this.$store.commit('v2/listingForm/SET_LOADING', false);
      }
    },
  },
};
</script>

<style scoped></style>
